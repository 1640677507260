import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import logo_svg from '../assets/img/general/logo.svg'
import Layout from '../components/layout'
import SEO from '../components/seo'

const PostVideo = ({ title, video_id, video_platform }) => {
  if (video_id && video_platform) {
    return (
      <div className="embed-responsive embed-responsive-16by9">
        {video_platform === 'youtube' ? (
          <iframe
            className="embed-responsive-item"
            src={`https://www.youtube.com/embed/${video_id}`}
            title={`${title}`}
            rameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <iframe
            className="embed-responsive-item"
            src={`https://player.vimeo.com/video/${video_id}`}
            title={`${title}`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        )}
      </div>
    )
  }
  return <></>
}

const PostArticle = ({ post }) => {
  const [readMore, setReadMore] = useState(false)
  return (
    <>
      <article className="my-4">
        <p>{readMore && post.date}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: readMore ? post.content : post.excerpt,
          }}
        />
      </article>
      {!readMore ? (
        <button
          className="btn btn-sm btn-success text-uppercase"
          onClick={() => {
            setReadMore(!readMore)
          }}
        >
          Read more
        </button>
      ) : (
        <button
          className="btn btn-sm btn-success text-uppercase mt-4"
          onClick={() => {
            window.history.back()
          }}
        >
          Back
        </button>
      )}
    </>
  )
}

const ImageGallery = ({ images }) => {
  if (images) {
    return (
      <div className="card-columns mt-5">
        {images.map((postImage, index) => (
          <div className="card border-0" key={index}>
            {postImage.image && postImage.image.localFile && (
              <Img
              fluid={postImage.image.localFile.childImageSharp.fluid}
                alt={postImage.image.title}
                className="img-thumbnail"
              />
            )}
          </div>
        ))}
      </div>
    )
  }
  return <></>
}

const ProjectsSinglePostTemplate = ({ data }) => {
  const post = data.wordpressPost
  const { acf } = post
  return (
    <Layout>
      <SEO title={post.title} description={post.excerpt} />
      <section className="bg-dark text-white py-5">
        <div className="container text-center">
          <img
            src={logo_svg}
            className="img-fluid d-block mx-auto mt-3 mb-4"
            width="250"
            alt="Energi Impact logo"
          />
          <h1 className="font-weight-bold mb-4 text-uppercase">{post.title}</h1>

          <PostVideo
            {...(acf &&
              !!acf.project_single_post &&
              !!acf.project_single_post.video_section &&
              acf.project_single_post.video_section)}
            title={post.title}
          />
          <PostArticle post={post} />
          <ImageGallery
            images={
              acf &&
              !!acf.project_single_post &&
              !!acf.project_single_post.image_gallery &&
              acf.project_single_post.image_gallery
            }
          />
        </div>
      </section>
    </Layout>
  )
}
export default ProjectsSinglePostTemplate
export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      acf {
        project_single_post {
          video_section {
            video_id
            video_platform
          }
          image_gallery {
            image {
              title
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
